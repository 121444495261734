import React from "react";
import "./LocationAndHours.css";

const Map = () => {
  return (
    <section className="home-map-container">
      <iframe
        title="Our Location on Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.402415236151!2d-73.99655082373867!3d40.59894424452882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c245d72ff72ddd%3A0x9db7b050c7af7250!2sBenson%20Dental%20Studio!5e0!3m2!1sen!2sus!4v1721970436808!5m2!1sen!2sus"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

const Hours = () => {
  const getCurrentDay = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date();
    const estDate = new Date(
      date.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    return days[estDate.getDay()];
  };

  const days = [
    { name: "Monday", hours: "9 AM - 5 PM" },
    { name: "Tuesday", hours: "9 AM - 5 PM" },
    { name: "Wednesday", hours: "9 AM - 5 PM" },
    { name: "Thursday", hours: "9 AM - 5 PM" },
    { name: "Friday", hours: "9 AM - 5 PM" },
    { name: "Saturday", hours: "10 AM - 3 PM" },
    { name: "Sunday", hours: "Closed" },
  ];

  return (
    <div>
      <h2>Hours</h2>
      <section className="hours-container">
        <div className="days">Monday - Friday</div>
        <div className="hours">9-5</div>
      </section>
    </div>
  );
};

const LocationAndHours = () => {
  return (
    <>
      <Map />
    </>
  );
};

export default LocationAndHours;
