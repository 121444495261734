import React, { useState } from "react";
import "./Header.css";
import HeaderMenu from "./HeaderMenu";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate(""); // This will redirect to the home page
  };

  return (
    <section className="menu-header">
      <div className="header-content">
        <div className="logo-container" onClick={onLogoClick}>
          <img alt="benson dental studio logo" className="logo-img" src="/benson-logo.png"></img>
        </div>
        <HeaderMenu />
      </div>
    </section>
  );
};

export default Header;
