import React from "react";
import "./About.css";
import { useState } from "react";

const drAlina = () => {
  return (
    <article>
      <p>
        Dr. Alina Aga, a well-respected doctor in Brooklyn, earned her Doctorate
        from NYU College of Dentistry. She is known for her excellent patient
        care and has earned great admiration from her patients.
      </p>
      <p>
        Outside of work, Dr. Aga loves spending time with her family, especially
        her 1-year-old son. She also enjoys interior designing her new home.
      </p>
      <p>
        She is dedicated to community service, often volunteering at local
        health fairs and dental awareness programs. Dr. Aga is a proud member of
        the
        <span className="text-highlight">
          American Dental Association (ADA)
        </span>
        and the
        <span className="text-highlight">
          Second District Dental Society (ADA),
        </span>
        showing her commitment to maintaining high standards in dental care.
      </p>
    </article>
  );
};

const drAndrii = () => {
  return (
    <article>
      <p>
        Dr. Andrii Slobodian, is an experienced dentist with advanced education
        in Orofacial Pain. Originally from Ukraine, Dr. Slobodian completed two
        years of oral surgery residency before moving to the United States,
        where he graduated from SUNY at Buffalo School of Dental Medicine.
      </p>
      <p>
        {" "}
        Dr. Andrii provides advanced care for restorative needs, including tooth
        loss. He places all types of dental implants and has the expertise to
        address complex dental problems with leading oral surgical solutions.
      </p>
      <p>
        Dr. Andrii was previously a professional soccer player and remains an
        active family man in his free time. He has two daughters and two sons.
        On the weekends, he enjoys spending time with family, fishing, and
        hiking.
      </p>
    </article>
  );
};

const DoctorSection = ({ doctor }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="doctor-container">
      <div className="doctor-profile">
        <div className="profile-image-overlay">
          <img
            src={isHovered ? doctor.flippedImage : doctor.image}
            alt={`portrait of ${doctor.name}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
          <div className="profile-details">
            <h2>{doctor.name}</h2>
            <p>{doctor.title}</p>
          </div>
        </div>
      </div>

      <div className="doctor-about">{doctor.description}</div>
    </section>
  );
};

const About = () => {
  const doctors = [
    {
      name: "Alina Aga, DDS",
      title: "General Dentist & Cosmetic Dentist",
      description: drAlina(),
      image: "/imgs/dr-alina.png",
      flippedImage: "/imgs/dr-alina.png",
    },
    {
      name: "Andrii Slobodian, DDS",
      title: "General Dentist & Cosmetic Dentist",
      description: drAndrii(),
      image: "/imgs/dr-andrii.png",
      flippedImage: "/imgs/dr-andrii.png",
    },
  ];

  return (
    <main className="center-container fade-in">
      {doctors.map((doctor, index) => DoctorSection({ doctor }))}
    </main>
  );
};

export default About;
