import React, { act, createRef, useEffect, useRef, useState } from "react";
import {
  CardGroup,
  Card,
  CardContent,
  CardHeader,
  Image,
  CardDescription,
  Icon,
  Divider,
} from "semantic-ui-react";

import "./Services.css";
import services from "./services.json";

const ServiceCard = ({ color, image, title, description, priceRange }) => {
  return (
    <>
      <Card color={color} className="service-card">
        {/* <Image
          className="service-card-image"
          src={image}
          wapped
          fluid
          size="medium"
          ui={true}
          alt={title}
        /> */}
        <CardContent>
          <CardHeader>{title}</CardHeader>
          <CardDescription>{description}</CardDescription>
        </CardContent>
        <CardContent extra>
          <a>
            <Icon name="dollar" />
            {priceRange}
          </a>
        </CardContent>
      </Card>
    </>
  );
};

const ServiceMenu = ({ onMenuChange }) => {
  const [state, setState] = useState({ activeItem: "Cosmetic" });
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef(null);
  const { activeItem } = state;

  const handleScroll = () => {
    if (menuRef.current) {
      setIsSticky(window.scrollY > menuRef.current.offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItemClick = (name) => {
    console.log(name);
    setState({ activeItem: name });
    onMenuChange(name);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  return (
    <div
      ref={menuRef}
      className={`secondary-menu sticky-menu`}
      // className={`secondary-menu sticky-menu ${isSticky ? "sticky" : ""}`}
    >
      {services.map((service, index) => (
        <div
          className={
            activeItem === service.title ? "link active fade-in" : "link"
          }
          onClick={() => {
            handleItemClick(service.title);
          }}
        >
          {service.title}
        </div>
      ))}
    </div>
  );
};

const colors = ["pink", "blue", "green", "yellow", "red", "purple"];

const Services = () => {
  const [activeCategory, setActiveCategory] = useState(services[0].title);

  const handleMenuChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <main className="center-container fade-in">
      <ServiceMenu onMenuChange={handleMenuChange} />
      {services.map(
        (service, index) =>
          service.title === activeCategory && (
            <div className="fade-in">
              <Divider className="service-category-divider" horizontal>
                {service.title}
              </Divider>
              <CardGroup doubling itemsPerRow={2}>
                {service.cards.map((card, cardIndex) => (
                  <ServiceCard
                    color={colors[index % colors.length]}
                    key={index}
                    image={`/imgs/services/${card.image}`}
                    title={card.title}
                    description={card.description}
                    priceRange={card.priceRange}
                  />
                ))}
              </CardGroup>
            </div>
          )
      )}
    </main>
  );
};

export default Services;
