import React from "react";
import "./Home.css";
import LocationAndHours from "./LocationAndHours";
import services from "../Services/services.json";
import { Card } from "semantic-ui-react";

const Hero = () => {
  return (
    <section className="home-container">
      <div
        className="home-hero-container"
        aria-label="Image of the dental clinic office"
      >
        {/* <header className="hero">
          <h1>Your modern smile destination in Brooklyn, New York.</h1>
          <button className="book-button">Book Online</button>
        </header> */}
      </div>
    </section>
  );
};

const Home = () => (
  <main className="home-main-container fade-in">
    <Hero />
    <section className="home-container">
      <div>
        <article className="welcome-message">
          <h1>Come visit us at 2260 Benson Ave, Brooklyn, NY 11214.</h1>

          <p>
            At Benson Dental Studio, your smile is our priority. Located in
            Bensonhurst, Brooklyn, we offer a wide range of dental services
            tailored to meet your needs. Whether it's a routine check-up,
            cosmetic dentistry, or restorative procedures, our team is here to
            provide top-notch care in a friendly environment.
          </p>
          <p>
            Convenitely located on Benson Ave and 23rd Ave, a short walk from
            the D train.
          </p>
          <h2>Hours</h2>
          <section className="hours-container">
            <div className="day-and-hours">
              <div className="days">Monday - Friday</div>
              <div className="hours">9-5 PM</div>
            </div>
            <div className="day-and-hours">
              <div className="days">Saturday</div>
              <div className="hours">10-3 PM</div>
            </div>
            <div className="day-and-hours">
              <div className="days">Sunday</div>
              <div className="hours">Closed</div>
            </div>
          </section>
        </article>
      </div>
      <LocationAndHours />
    </section>
    <section style={{ display: "none" }}>
      <div> Services</div>
      {services.map((service, index) => (
        <article>
          <div>{service.title}</div>
          {service.cards.map((card, index) => (
            <div>
              <div>{card.title}</div>
              <p>{card.description}</p>
            </div>
          ))}
        </article>
      ))}
    </section>
  </main>
);

export default Home;
