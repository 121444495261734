import React from "react";
import "./Contact.css";
import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardGroup,
  CardHeader,
  Container,
  Divider,
  Form,
  FormField,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
  Input,
  Select,
  TextArea,
} from "semantic-ui-react";

const ContactForm = () => {
  const [result, setResult] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [name, setName] = useState("");

  const checkEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    if (email.length > 5 && re.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    console.log(email);
  };
  const onSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    if (!validEmail) {
      return;
    }

    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "dd2c665a-7e11-4a64-9eba-816132075e1d");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div>
      {result ? (
        <div
          style={{
            padding: "1rem",
            margin: "1rem",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          {result}
          <a
            href="#"
            onClick={() => {
              setResult(null);
            }}
          >
            Back
          </a>
        </div>
      ) : (
        <Form className="contact-form" onSubmit={onSubmit}>
          <div className="blurb">
            We'd love to hear from you! Whether you have a question about our
            services, pricing, or anything else, our team is ready to answer all
            your questions.
          </div>

          <FormGroup widths="equal">
            <FormField
              id="form-input-control-name"
              control={Input}
              placeholder="name"
              type="text"
              name="name"
            />
          </FormGroup>
          <FormField
            id="form-textarea-control-feedback"
            control={TextArea}
            placeholder="feedback"
            type="text"
            name="feedback"
          />
          <FormField
            id="form-input-control-error-email"
            control={Input}
            placeholder="email"
            type="email"
            name="email"
            onChange={(e) => {
              checkEmail(e.target.value);
            }}
            error={
              isSubmitting && !validEmail
                ? {
                    content: "Please enter a valid email address",
                    pointing: "above",
                  }
                : null
            }
          />
          <FormField
            id="form-button-control-public"
            control={Button}
            content="submit"
            type="text"
            name="name"
          />
        </Form>
      )}
    </div>
  );
};

const Contact = () => {
  return (
    <main className="center-container fade-in">
      <section className="contact-container">
        <section className="contact-cards">
          <article
            onClick={() => {
              window.open("mailto:hello@bensondentalstudio.com");
            }}
            className="contact-cards-card"
          >
            <i className={`fa-solid fa-envelope`}></i>
            <span className="content">hello@bensondentalstudio.com</span>
          </article>
          <article
            onClick={() => {
              window.open("tel:718.266.5889");
            }}
            className="contact-cards-card"
          >
            <i className={`fa-solid fa-phone`}></i>
            <span className="content">718.266.5889</span>
          </article>

          <article
            onClick={() => {
              window.open("https://www.instagram.com/benson_dental_studio/");
            }}
            className="contact-cards-card"
          >
            <i className={`fa-solid fa-brands fa-instagram`}></i>
            <span className="content">benson_dental_studio</span>
          </article>
          {/* <article onClick={()=>{window.open("https://www.instagram.com/benson_dental_studio/")}} className="contact-cards-card">
            <i className={`fa-solid fa-brands fa-facebook`}></i>
            <span className="content">
                benson_dental_studio
            </span>
          </article>
          <article onClick={()=>{window.open("https://www.instagram.com/benson_dental_studio/")}} className="contact-cards-card">
            <i className={`fa-solid fa-brands fa-yelp`}></i>
            <span className="content">
                benson_dental_studio
            </span>
          </article> */}
        </section>

        <Divider horizontal>OR CONTACT US DIRECTLY</Divider>
        <ContactForm />
      </section>
    </main>
  );
};

export default Contact;
