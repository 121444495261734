import React, { useState } from "react";
import { Menu, Sidebar, Icon } from "semantic-ui-react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Link, useNavigate } from "react-router-dom";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [activeItem, setActiveItem] = useState("HOME");
  const handleItemClick = (name, path) => {
    setActiveItem(name);
    navigate(path);
    setVisible(false);
  };
  const toggleVisibility = () => setVisible(!visible);

  const getMenuItems = () => {
    const generateItem = (name, path, icon) => {
      return (
        <span className="menu-item" onClick={() => handleItemClick(name, path)}>
          <Menu.Item active={activeItem === name} as={Link} to={path}>
            {name}
          </Menu.Item>
          <i className={`fa-solid fa-${icon}`}></i>
        </span>
      );
    };
    return (
      <>
        {generateItem("HOME", "/", "house")}
        {generateItem("SERVICES", "/services", "tooth")}
        {generateItem("ABOUT", "/about", "user-doctor")}
        {generateItem("CONTACT", "/contact", "phone xl")}
      </>
    );
  };

  const getSiderBarMenu = () => {
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        onHide={() => setVisible(false)}
        visible={visible}
        direction="right"
        width="thin"
        className="sidebar-menu"
      >
        <Menu text vertical size="huge">
          {getMenuItems()}
        </Menu>
      </Sidebar>
    );
  };

  const getMenu = () => {
    return (
      <>
        <Menu text size="large">
          {getMenuItems()}
          <button className="book-button icon call">
            <Icon call name="calendar" />
            Book Online
          </button>
        </Menu>
      </>
    );
  };

  return (
    <div>
      <div className="hamburger-menu-icon">
        <Icon name="sidebar" size="large" onClick={toggleVisibility} />
      </div>
      {getSiderBarMenu()}
      <div className="full-menu">{getMenu()}</div>
    </div>
  );
};

export default HeaderMenu;
